<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.png" alt="" height="22" width="10">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
        </a>
        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/lego.jpg" alt=""width="50" height="50" style="
              margin-left: -12px;
              border-radius: 50px 50px 50px 50px;">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo.png" alt="" height="60px">
          </span>
        </a>
      </div>
      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>
    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown">
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
<!--      <div class="row">-->
<!--        <div class="col-lg-12">-->
<!--          <div class="send-btn">-->
<!--            <button (click)="open(mymodal)" class="btn-sm btn-primary">Create Invite Link </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <ng-template #mymodal let-modal>
        <div class="modal-header">
          <h4 class="page-title" id="modal-basic-title">Create Invite Link </h4>
          <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span class="close-text">X</span>
          </button>
          <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
          <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button> -->
        </div>
        <div class="modal-body">
          <div>
            <div class="card-header text-center pt-8 pt-md-4 pb-0 pb-md-4">
              <div class="row">
                <textarea style="text-align: center;" class="form-control" id="clipboard-example-textarea" cols="50"
                  readonly [(ngModel)]="you_tube_link"></textarea>
              </div>
            </div>
            <div class="card-body pt-0 pt-md-4 card-icons">
              <div class="row" style="text-align: center;">
                <div>
                  <h4 class="page-title" id="modal-basic-title">Share this: </h4>
                  <!-- <h4><b>Share this :</b></h4> -->
                </div>
                <div class="col">
                  <div>
                    <a (click)="fb($event)" style="border-radius: 50%;"><img
                        src="../../../assets/images/icons/facebook1.png" alt=""></a>
                    <a (click)="gmail($event)" style="margin-left: 50px;border-radius: 50%;"><img
                        src="../../../assets/images/icons/gmail1.png" alt=""></a>
                    <a (click)="twitter($event)" style="margin-left: 50px;border-radius: 50%;"><img
                        src="../../../assets/images/icons/twitter.png" alt=""></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn-sm btn-primary" 
          (click)="copyToClipboard()" [cdkCopyToClipboard]="you_tube_link">
            Copy
          </button>
        </div>
      </ng-template>
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src="assets/images/users/user.png" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1"><span class="full-name-style">{{ full_name }}</span></span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item text-primary" href="javascript: void(0);" (click)="passwordChange()">
            <i class="mdi mdi-account-key font-size-16 align-middle me-1 text-primary"></i>
            Change Password</a>
            <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>

      </div>
    </div>
  </div>
</header>
