import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(
    private toast: HotToastService
  ) { }

  inputError(){
    this.toast.error('Invalid Input. Try Again');
  }

  showError(message: string){
    this.toast.error(message);
  }

  showErrorAndSuccess(message: string){
    this.toast.success(message, {
    duration: 5000,
      style: {
        border: '#FFFFFF',
        padding: '16px',
        color: '	#282828',
      },
      iconTheme: {
        primary: '#FFFF00',
        secondary: '#000000',
      },
    });
  }
  
  success(){
    this.toast.success('Data Saved Successfully');
  }

  customSuccess(messge: string){
    this.toast.success(messge);
  }

  uploading(){
    return this.toast.loading("Uploading, Please Wait...")
  }

  observe(loading_message:string, success_message: string, error_message: string){
    this.toast.observe(
      {
        loading: loading_message,
        success: success_message,
        error: error_message,
      }
    )
  }
  sendNotification(){
    this.toast.success('Notification Send Successfully');
  }

}
