import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { CurdService } from 'src/app/core/services/curd.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ToastService } from 'src/app/core/services/toast.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  full_name: any;
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;

  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    private curd: CurdService,
    private toast: ToastService,
    private auth: AuthenticationService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private modalService: NgbModal,
    private cdkCopyToClipboard: ClipboardModule

  ) {
  }

  openMobileMenu: boolean;
  closeResult: string;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.getUser();
    this.openMobileMenu = false;
    this.element = document.documentElement;
  }

  you_tube_link = "https://tracker.fitlygo.com/invite/";

  fb(e) {

    let url = 'www.google.com';
    e.preventDefault();
    var facebookWindow = window.open(
      'https://www.facebook.com/sharer/sharer.php?u='+   url ,
      'facebook-popup',
      'height=350,width=600'
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
    return false;
  }

  twitter(e) {

    let url = 'www.google.com';
    e.preventDefault();
    var twitterWindow = window.open(
      'https://twitter.com/intent/tweet?url=<?=urlencode($url)?>'+   url ,
      'facebook-popup',
      'height=350,width=600'
    );
    if (twitterWindow.focus) {
      twitterWindow.focus();
    }
    return false;
  }

  gmail(e) {

    let url = 'www.google.com';
    e.preventDefault();
    var gmailWindow = window.open(
      'https://mail.google.com/mail/?view=cm&fs=1&tf=1'+   url ,
      'facebook-popup',
      'height=350,width=600'
    );
    if (gmailWindow.focus) {
      gmailWindow.focus();
    }
    return false;
  }

  getUser() {
    this.auth.getUser().subscribe(res => {
      if (res) {
        this.you_tube_link=`${'https://tracker..com/login/'}${res.uid}`
        const user_id = res.uid
        this.curd.show('system_users', user_id).get().subscribe(res => {
          let user_info = res.data();
          // @ts-ignore
          if(user_info.displayName){
          // @ts-ignore
          // @ts-ignore

            this.full_name = user_info.displayName
          }
        })
      }
    })
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
    this.router.navigate(['/account/login']);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  send() {


  }

  passwordChange(){
    this.auth.getUser().subscribe(user => {
      this.router.navigate(['system-user/edit',user.uid]);
    });
  }

  copyToClipboard(){
    this.toast.customSuccess('Copied to clipboard');
  }
}
