import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 333,
        label: 'Approvals',
        icon: 'bx-check',
        subItems: [
            {
                id: 3,
                label: 'Challenges',
                link: 'approvals/challenges',
                parentId: 3
            },
            {
                id: 2,
                label: 'Articles',
                link: 'approvals/articals',
                parentId: 3
            },
            {
                id: 4,
                label: 'Offers',
                link: 'approvals/offers',
                parentId: 3
            },
        ]
    },
    {
        id: 11,
        label: 'Donations',
        icon: 'bx-briefcase-alt-2',
        link: 'donations',
    },
    {
        id: 3,
        label: 'Challenges',
        icon: 'bx-tone',
        subItems: [
            // {
            //     id: 1,
            //     label: 'Add User To Challenge',
            //     link: 'challenges/add-user-to-challenge',
            //     parentId: 3
            // },

            {
                id: 3,
                label: 'Create',
                link: 'challenge/create',
                parentId: 3
            },
            {
                id: 2,
                label: 'List',
                link: 'challenges/list',
                parentId: 3
            },
        ]
    },

    {
        id: 10,
        label: 'Sponsored Challenge',
        icon: 'bx-file',
        subItems: [
            {
                id: 13,
                label: 'Create',
                link: 'challenge/sponsored-challenge-create',
                parentId: 2
            },
            {
                id: 18,
                label: 'List',
                link: 'challenge/sponsored-challenge-list',
                parentId: 2
            },
        ]
    },
    {
        id: 2,
        label: 'Article',
        icon: 'bx-file',
        subItems: [
            {
                id: 13,
                label: 'Create',
                link: 'article/create',
                parentId: 2
            },
            {
                id: 18,
                label: 'List',
                link: 'article/list',
                parentId: 2,
            },
        ]
    },
    {
        id: 12,
        label: 'Offer Category',
        icon: 'bx-receipt',
        subItems: [
            {
                id: 13,
                label: 'Create',
                link: 'offer-category/create',
                parentId: 2
            },
            {
                id: 18,
                label: 'List',
                link: 'offer-categories/list',
                parentId: 2
            },
        ]
    },
    {
        id: 6,
        label: 'Offers',
        icon: 'bx-briefcase-alt-2',
        subItems: [
            {
                id: 1,
                label: 'Create',
                link: 'offer/create',
                parentId: 6
            },
            {
                id: 2,
                label: 'List',
                link: 'offers/list',
                parentId: 6
            },
        ]
    },
    {
        id: 4,
        label: 'Users',
        icon: 'bxs-user-detail',
        link: 'users/list',
        //   subItems: [
        //       {
        //           id: 2,
        //           label: 'List',
        //           link: 'users/list',
        //           parentId: 4
        //       },
        //   ]
    },
    {
        id: 4,
        label: 'Send Notifications',
        icon: 'bx-envelope',
        link: 'notifications/list',
    },
    {
        id: 6,
        label: 'Pending Notifications',
        icon: 'bx-briefcase-alt-2',
        link: 'notifications/pending-notifications',

    },
    {
        id: 7,
        label: 'System Users',
        icon: 'bxs-user-detail',
        subItems: [
            {
                id: 1,
                label: 'Create',
                link: 'system-user/create',
                parentId: 4
            },
            {
                id: 2,
                label: 'List',
                link: 'system-users/list',
                parentId: 4
            },
        ]
    },
    {
        id: 8,
        label: 'Corporate Users',
        icon: 'bx-user-circle',
        subItems: [
            {
                id: 1,
                label: 'Create',
                link: 'corporate-user/create',
                parentId: 4
            },
            {
                id: 2,
                label: 'List',
                link: 'corporate-users/list',
                parentId: 4
            },
        ]
    },
    {
        id: 9,
        label: 'Chat List',
        icon: 'bx-chat',
        link: 'chat/chat-list',
    },
    {
        id: 10,
        label: 'Reported Chat List',
        icon: 'bx-file',
        link: 'chat/reported-chat-list',
    },

];

