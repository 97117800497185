<footer class="footer">
    <div class="container-fluid footer-text">
        <div class="row">
            <div class="col-sm-6">
                {{year}} © Fitlygo.
            </div>
<!--            <div class="col-sm-6">-->
<!--                <div class="text-sm-end d-none d-sm-block">-->
<!--                  Design & Developed By Innaun IT-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</footer>
